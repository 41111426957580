import {
  ConsoleInstrumentation, Faro,
  getWebInstrumentations,
  initializeFaro as initialize
} from '@grafana/faro-react'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'

const initializeFaro: () => Faro = (): Faro => {

  return initialize({
    url: process.env.NEXT_PUBLIC_GRAFANA_FARO_COLLECTOR_URL,
    app: {
      name: 'crunch',
      version: '1.0.0',
      environment: process.env.NODE_ENV
    },
    instrumentations: [...getWebInstrumentations(), new TracingInstrumentation(), new ConsoleInstrumentation()]
  })
}

export default initializeFaro
